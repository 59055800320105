import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class ChampionsLeague extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    let [{
      freeKicks: mCLFreeKicks,
      goals: mCLGoals,
      assists: mCLAssists,
      hatTricks: mCLHatTricks,
      goalsPerGame: mCLGoalsPerGame,
      minsPerGoal: mCLMinsPerGoal,
      minsPerGoalContribution: mCLMinsPerGoalCont,
      apps: mCLApps,
    }] = mdata.filter(d => d.node.competition === "UCL Groups + Knockouts").map(d => d.node)

    let [{
      freeKicks: rCLFreeKicks,
      goals: rCLGoals,
      assists: rCLAssists,
      hatTricks: rCLHatTricks,
      goalsPerGame: rCLGoalsPerGame,
      minsPerGoal: rCLMinsPerGoal,
      minsPerGoalContribution: rCLMinsPerGoalCont,
      apps: rCLApps,
    }] = rdata.filter(d => d.node.competition === "UCL Groups + Knockouts").map(d => d.node)

    let [{
      goals: mCLGroupGoals,
      assists: mCLGroupAssists,
      apps: mCLGroupApps,
    }] = mdata.filter(d => d.node.competition === "UCL Group Stage").map(d => d.node)

    let [{
      goals: rCLGroupGoals,
      assists: rCLGroupAssists,
      apps: rCLGroupApps,
    }] = rdata.filter(d => d.node.competition === "UCL Group Stage").map(d => d.node)

    let [{
      goals: mCLKOGoals,
      assists: mCLKOAssists,
      minsPerGoal: mCLKOMinsPerGoal,
      minsPerGoalContribution: mCLKOMinsPerGoalCont,
      apps: mCLKOApps,
    }] = mdata.filter(d => d.node.competition === "UCL Knockouts").map(d => d.node)

    let [{
      goals: rCLKOGoals,
      assists: rCLKOAssists,
      minsPerGoal: rCLKOMinsPerGoal,
      minsPerGoalContribution: rCLKOMinsPerGoalCont,
      apps: rCLKOApps,
    }] = rdata.filter(d => d.node.competition === "UCL Knockouts").map(d => d.node)

    let [{
      goals: mCLFGoals,
      assists: mCLFAssists,
      apps: mCLFApps,
    }] = mdata.filter(d => d.node.competition === "UCL Finals").map(d => d.node)

    let [{
      goals: rCLFGoals,
      assists: rCLFAssists,
      apps: rCLFApps,
    }] = rdata.filter(d => d.node.competition === "UCL Finals").map(d => d.node)

    let [{
      keyPasses: mCL09KeyPasses,
      successfulDribbles: mCL09Dribbles,
      motm: mCL09Motm,
      avgRating: mCL09AvgRating,
      shotsPerGoal: mCL09ShotsPerGoal,
      aerialDuels: mCL09AerialDuels,
      apps: mCL09Apps,
    }] = mdata.filter(d => d.node.competition === "Europe Since 09").map(d => d.node)

    let [{
      keyPasses: rCL09KeyPasses,
      successfulDribbles: rCL09Dribbles,
      motm: rCL09Motm,
      avgRating: rCL09AvgRating,
      shotsPerGoal: rCL09ShotsPerGoal,
      aerialDuels: rCL09AerialDuels,
      apps: rCL09Apps,
    }] = rdata.filter(d => d.node.competition === "Europe Since 09").map(d => d.node)

    return (
      <Layout>
        <SEO
          title={"Messi vs Ronaldo in UEFA Champions League - Knockout Stage and Group Stage Stats"}
          description={`Who has scored more goals in the Champions League knockout stages? We lay out all the Messi and Ronaldo stats for each round of the Champions League.`}
          canonicalPath={`/club-stats/champions-league/`}
        />

        <h1>
          <span className="sr-only">Messi vs Ronaldo</span> Champions League <span className="sr-only">Knockout Goals and</span> Stats
        </h1>

        

        <CompetitionBlock competition="UCL Groups + Knockouts" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Main Draw Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Knockouts" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Knockout Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Finals" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Final Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Semi-finals" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Semi-final Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Quarter-finals" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Quarter-final Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Last 16" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Last 16 Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Group Stage" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Group Stage Matches" perfmsg="Since 2009/10" />

        <CompetitionBlock competition="UCL Qualifiers" type="all" mdata={mdata} rdata={rdata} subtitle="All Champions League Qualifying Matches" />

        <CompetitionBlock competition="UEFA Cup / Europa League" type="all" mdata={mdata} rdata={rdata} subtitle="All UEFA Cup / Europa League Matches" />

        <CopyBlock classes="mt-16">
          <h2>Messi vs Ronaldo in the UEFA Champions League</h2>

          <InfoBar />

          <p>The Champions League is the world's greatest club football competition. The World Cup still holds the status as the most prestigious trophy in world football, but in terms of quality, the Champions League is the pinnacle, with all of the world's best players involved every year.</p>

          <p>Both Lionel Messi and Cristiano Ronaldo have enjoyed great success in the competition, with Messi lifting the trophy <strong className="text-messi">4 times</strong> with Barcelona, whilst Ronaldo has won it <strong className="text-ronaldo">5 times</strong>; once with Manchester United and fours times with Real Madrid.</p>

          <h3>Champions League Goals</h3>

          <p>Unsurprisingly, Messi and Ronaldo are the two top goalscorers in Champions League history, with Ronaldo leading the way on <strong className="text-ronaldo">{rCLGoals} goals</strong> (+1 in qualifying) in {rCLApps} games, whilst Messi is {Number(rCLGoals)-Number(mCLGoals)} behind on <strong className="text-messi">{mCLGoals} goals</strong> in {mCLApps} games.</p>

          <p>Interestingly though, it was actually Leo Messi who first surpassed the previous all time goals record of 71, set by Real Madrid legend, Raúl. Messi broke the record with a hat-trick against APOEL in November 2014, putting him on 74 Champions League goals; 4 ahead of Cristiano Ronaldo who was on 70 goals at the time.</p>

          <p>The fact that Ronaldo is now {Number(rCLGoals)-Number(mCLGoals)} goals ahead of Messi is testament to how relentlessly he has found the net in the last 5 years, regularly hitting double figures in the seasons that followed.</p>

          <p>In fact, Cristiano Ronaldo scored <strong className="text-ronaldo">10 or more goals in <em>seven</em> consecutive seasons</strong> from 2011/12 to 2017/18, his best return coming in 2013/14 when he scored a stunning 17 goals in 11 appearances. This, of course, is a Champions League record for most goals scored in a single season. </p>

          <h4>Most goals in a single Champions League season</h4>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <tr>
                <th>Rank</th>
                <th>Player</th>
                <th>Season</th>
                <th>Goals</th>
                <th>Games</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Cristiano Ronaldo</td>
                <td>2013/14</td>
                <td>17</td>
                <td>11</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Cristiano Ronaldo</td>
                <td>2015/16</td>
                <td>16</td>
                <td>12</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Robert Lewandowski</td>
                <td>2019/20</td>
                <td>15</td>
                <td>10</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Cristiano Ronaldo</td>
                <td>2017/18</td>
                <td>15</td>
                <td>13</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Karim Benzema</td>
                <td>2021/22</td>
                <td>15</td>
                <td>12</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Lionel Messi</td>
                <td>2011/12</td>
                <td>14</td>
                <td>11</td>
              </tr>
              <tr>
                <td>6</td>
                <td>José Altafini</td>
                <td>1962/63</td>
                <td>14</td>
                <td>9</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Robert Lewandowski</td>
                <td>2021/22</td>
                <td>13</td>
                <td>10</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Lionel Messi</td>
                <td>2010/11</td>
                <td>12</td>
                <td>13</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Lionel Messi</td>
                <td>2018/19</td>
                <td>12</td>
                <td>10</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Cristiano Ronaldo</td>
                <td>2012/13</td>
                <td>12</td>
                <td>12</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Cristiano Ronaldo</td>
                <td>2016/17</td>
                <td>12</td>
                <td>13</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Ferenc Puskas</td>
                <td>1959/60</td>
                <td>12</td>
                <td>7</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Ruud van Nistelrooy</td>
                <td>2002/03</td>
                <td>12</td>
                <td>9</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Mario Gómez</td>
                <td>2011/12</td>
                <td>12</td>
                <td>13</td>
              </tr>
            </table>
          </div>

          <p>Ronaldo has had multiple fantastic goalscoring seasons in the Champions League, finishing as the <strong className="text-ronaldo">top scorer on 7 occasions</strong>, whilst Messi has finished as the Champions League <strong className="text-messi">top scorer 6 times</strong> in his career.</p>

          <p>Despite Cristiano Ronaldo's stunning goal-surge in recent years, Messi still actually boasts the superior ratios, scoring <strong className="text-messi">{parseFloat(mCLGoalsPerGame).toFixed(2)} goals per game</strong> compared to Ronaldo's <strong className="text-ronaldo">{parseFloat(rCLGoalsPerGame).toFixed(2)} goals per game</strong>.</p>

          <p>If we drill down into minutes per goal, again Messi has the edge here, soring <strong className="text-messi">a goal every {parseFloat(mCLMinsPerGoal).toFixed(1)} minutes</strong>, whereas Ronaldo has scored <strong className="text-ronaldo">a goal every {parseFloat(rCLMinsPerGoal).toFixed(1)} minutes</strong> in the Champions League.</p>

          <h4>Ronaldo Champions League Goals by Club</h4>

          <p>Believe it or not, Cristiano Ronaldo actually played 29 Champions League games for Manchester United before scoring his first goal! He finally broke his duck in the 7-1 victory over Roma in 2007, scoring 2 goals. As we now know, this really was a case of a long-awaited goal causing the floodgates to open.</p>

          <ul className="list-disc list-inside">
            <li>Manchester United: 22 goals in 62 games</li>
            <li>Real Madrid: 105 goals in 101 games (!)</li>
            <li>Juventus: 14 goals in 23 games</li>
          </ul>

          <h3>Champions League Assists</h3>

          <p>In general, we're used to Leo Messi having the ascendancy when it comes to assists, but it's actually Ronaldo who currently has the lead in assists in the UEFA Champions League, with <strong className="text-ronaldo">{rCLAssists} assists</strong> compared to Messi's <strong className="text-messi">{mCLAssists} assists</strong> (Opta).</p>

          <p>However, with Ronaldo clocking up {rCLApps-mCLApps} more games than Messi, it means Ronaldo averages <strong className="text-ronaldo">{parseFloat(rCLAssists/rCLApps).toFixed(2)} assists per game</strong>, whereas Messi averages <strong className="text-messi">{parseFloat(mCLAssists/mCLApps).toFixed(2)} assists per game</strong>.</p>

          <p>In terms of overall goal contributions, Messi leads the way, registering a <strong className="text-messi">goal or assist every {parseFloat(mCLMinsPerGoalCont).toFixed(1)} minutes</strong>, whilst Ronaldo records a <strong className="text-ronaldo">goal or assist every {parseFloat(rCLMinsPerGoalCont).toFixed(1)} minutes</strong>.</p>

          <h3>Champions League Group Stage Stats</h3>

          <p>In the group stages, it's Messi that boasts the better goalscoring stats, with <strong className="text-messi">{mCLGroupGoals} goals in {mCLGroupApps} games</strong> in the Champions League group stage, compared to Ronaldo's <strong className="text-ronaldo">{rCLGroupGoals} goals in {rCLGroupApps} games</strong>.</p>

          <p>So despite playing {rCLGroupApps-mCLGroupApps} fewer group stage matches, Messi has scored <strong className="text-messi">{mCLGroupGoals-rCLGroupGoals} more goals</strong> than Ronaldo at this stage of the compeition. </p>

          <p>In terms of assists in Champions League group stage matches, it's close, with Cristiano Ronaldo on <strong className="text-ronaldo">{rCLGroupAssists} assists</strong> and Messi on <strong className="text-messi">{mCLGroupAssists} assists</strong>. </p>

          <h4>Ronaldo Champions League Group Stage Goals by Club</h4>

          <ul className="list-disc list-inside">
            <li>Manchester United: 11 goals in 34 games</li>
            <li>Real Madrid: 55 goals in 49 games</li>
            <li>Juventus: 7 goals in 15 games</li>
          </ul>

          <h3>Champions League Knockout Stats</h3>

          <p>With Ronaldo being the all time top scorer in the Champions League and Messi having more group stage goals, you might have guessed that Ronaldo subsequently has significantly more in the knockout stages of the competition.</p>

          <p>Indeed, Cristiano has racked up an incredible amount of goals in the latter stages of the Champions League in recent years, as his Real Madrid side won 4 out of 5 trophies in an unprecedented spell of European dominance.</p>

          <p>In 2016, he scored a <Link to={`/detailed-stats/hat-tricks/`} className="text-highlight underline">hat-trick</Link> to rescue Real Madrid against <strong className="text-highlight">Wolfsburg</strong> in a quarter-final 2nd leg. In 2017 he became the first player to score back-to-back hat-tricks in the Champions League knockout stages, scoring 2 goals in extra-time to defeat <strong className="text-highlight">Bayern Munich</strong>, before hitting 3 past arch-rivals <strong className="text-highlight">Atletico Madrid</strong> in the following round. Two years later, now at Juventus, he scored another knockout stage hat-trick in the Last 16, again against Atletico who had won the 1st leg 2-0.</p>

          <p>In total, Ronaldo has scored <strong className="text-ronaldo">{rCLKOGoals} goals</strong> in the Champions League knockout stages in <strong className="text-ronaldo">{rCLKOApps} appearances</strong>, scoring <strong className="text-ronaldo">a goal every {parseFloat(rCLKOMinsPerGoal).toFixed(1)} minutes</strong> on average. Messi has scored <strong className="text-messi">{mCLKOGoals} goals</strong> in the Champions League knockout stages in <strong className="text-messi">{mCLKOApps} appearances</strong>, scoring <strong className="text-messi">a goal every {parseFloat(mCLKOMinsPerGoal).toFixed(1)} minutes</strong> on average.</p>

          <p>If we look at <Link to={`/detailed-stats/opta-assists/`} className="text-highlight underline">assists</Link>, Ronaldo has <strong className="text-ronaldo">{rCLKOAssists}</strong> knockout stage assists to Messi's <strong className="text-messi">{mCLKOAssists}</strong>; although Ronaldo has played {rCLKOApps-mCLKOApps} more games. Overall, Ronaldo <strong className="text-ronaldo">scores or assists every {parseFloat(rCLKOMinsPerGoalCont).toFixed(1)} minutes</strong> in Champions League knockout games, whereas Messi <strong className="text-messi">scores or assists every {parseFloat(mCLKOMinsPerGoalCont).toFixed(1)} minutes</strong>.</p>

          <p>Messi has also had some huge nights in the Champions League knockout rounds. His personal favourite is probably his brace in the 2011 semi-final at the Bernabeu in one of the biggest <strong className="text-highlight">El Clasico</strong> encounters in recent years. The second goal is one Messi claims to be his best ever goal, picking the ball up 30 yards from goal before proceeding to dribble through the entire Madrid defence and then sliding the ball past a bewildered Casillas.</p>

          <p>Another that stands out is Messi's destruction of <strong className="text-highlight">Bayern Munich</strong> in the 2015 semi-final, during which he literally floored Jerome Boateng through his movement alone. In 2019, Messi put on a masterclass against <strong className="text-highlight">Manchester United</strong> in the quarter-final 2nd leg, scoring twice. He then followed this up with another brace against <strong className="text-highlight">Liverpool</strong>, arguably the best side in the world at the time. His stunning 30 yard free kick ensured they took a 3-0 advantage to the away leg, but we all know what happened next. Messi did play well in the 2nd leg though, setting up 3 major chances for his team mates, but this was ultimately not enough.</p>

          <p>Cristiano Ronaldo's superb goalscoring stats in the Champions League knockout rounds are perhaps his greatest asset in the infamous GOAT debate.</p>

          <h4>Ronaldo Champions League Knockout Stage Goals by Club</h4>

          <ul className="list-disc list-inside">
            <li>Manchester United: 10 goals in 25 games</li>
            <li>Real Madrid: 50 goals in 52 games</li>
            <li>Juventus: 7 goals in 8 games</li>
          </ul>

          <h3>Champions League Finals</h3>

          <p>Both Messi and Ronaldo have good records in Champions League finals, appearing in 9 finals between them. In one of these finals, <strong className="text-highlight">the two players actually faced each other</strong>, with Messi's Barcelona defeating Ronaldo's <strong className="text-highlight">Manchester United</strong> in 2009 with goals from Eto'o and a famous Leo Messi header.</p>

          <p>Ronaldo was however on the winning side a year previous, as Man Utd beat <strong className="text-highlight">Chelsea</strong> on penalties; Ronaldo scored during the game but actually missed his penalty in the shootout. But when John Terry stepped up to take the winning penalty for Chelsea, he slipped, missed the goal and Ronaldo secured his first Champions League trophy.</p>

          <p>In 2011, Messi produced an outstanding performance as his Barcelona side completely outclassed <strong className="text-highlight">Manchester United</strong>, winning the game 3-1 with Messi scoring the crucial second goal.</p>

          <p>In 2017, Ronaldo scored 2 goals as Real Madrid ran out 4-1 winners against his future side <strong className="text-highlight">Juventus</strong>.</p>

          <p>Overall, Messi has scored <strong className="text-messi">{mCLFGoals} goals in {mCLFApps} final appearances, with {mCLFAssists} assists</strong>. Ronaldo has played in <strong className="text-ronaldo">{rCLFApps} Champions League finals, scoring {rCLFGoals} goals and providing {rCLFAssists} assist</strong>.</p>

          <p>In short, both players have delivered on the big stage, with Messi winning all 3 of the finals he's appeared in, whilst Ronaldo has won 5 out of 6.</p>

          <h3>Beyond the Goals</h3>

          <p>Of course, football is about more than just goals, which is easily forgotten when Messi and Ronaldo put the ball in the net so relentlessly.</p>

          <p>First of all, let's look at <strong className="text-highlight">passing</strong>. Since 2009/10, Messi has made <strong className="text-messi">{mCL09KeyPasses} key passes</strong> in {mCL09Apps} Champions League appearances, whereas Ronaldo has made <strong className="text-ronaldo">{rCL09KeyPasses} key passes</strong> in {rCL09Apps} games.</p>

          <p>When it comes to <strong className="text-highlight">dribbling</strong>, Messi has completed <strong className="text-messi">{mCL09Dribbles} successful dribbles</strong> in the Champions League since 2009/10, whilst Ronaldo has completed less than half Messi's total, with <strong className="text-ronaldo">{rCL09Dribbles} successful dribbles</strong> in the same period.</p>

          <p>It must be noted however that these above stats aren't particularly surprising when you consider their respective playing styles. Additionally, Ronaldo's dribbling and passing stats are likely to be higher before 2009 when he was more of a genuine winger and more involved in the build-up play.</p>

          <p>Ronaldo dominates when it comes to <strong className="text-highlight">winning the ball in the air</strong>, winning <strong className="text-ronaldo">{rCL09AerialDuels} aerial duels</strong> in the Champions League since 2009/10 compared to Messi's <strong className="text-messi">{mCL09AerialDuels} aerial duels</strong> won. Again, hardly surprising given their physical attributes and the playing styles of their teams.</p>

          <p>Ronaldo consistenly takes more <strong className="text-highlight">shots</strong> at goal than Leo Messi does, but it's the Argentinian who converts a greater proportion of his efforts into goals, scoring <strong className="text-messi">a goal for every {parseFloat(mCL09ShotsPerGoal).toFixed(2)} shots</strong> he's taken in the Champions League since 2009/10. Cristiano meanwhile has scored <strong className="text-ronaldo">a goal for every {parseFloat(rCL09ShotsPerGoal).toFixed(2)} shots</strong> he's taken.</p>

          <p>Taking the above stats (amongst others) into account alongside goals and assists, stats providers are able to produce <strong className="text-highlight">match ratings</strong> based on their algorithms. Lionel Messi's overall average match rating in the Champions League since 2009/10 is <strong className="text-messi">{parseFloat(mCL09AvgRating).toFixed(2)}</strong>, whilst Cristiano Ronaldo's overall match rating is <strong className="text-ronaldo">{parseFloat(rCL09AvgRating).toFixed(2)}</strong>.</p>

          <p>With those average ratings, it follows that Messi has also received a greater number of <strong className="text-highlight">Man of the Match awards</strong> in the Champions League, picking up <strong className="text-messi">{mCL09Motm} awards since 2009/10 ({mCL09Apps} games)</strong>, compared to Ronaldo's <strong className="text-ronaldo">{rCL09Motm} man of the match awards ({rCL09Apps} games)</strong>.</p>

          <figure>
            <Img fadeIn={false} fluid={this.props.data.ratingsImg.childImageSharp.fluid} style={{width: 450, maxWidth: 100+'%'}} className="mt-8" alt="Graph showing average match ratings in the Champions League by season" />
            <figcaption>Average match ratings in the Champions League for each season since 2009/10</figcaption>
          </figure>

          <h3>Champions League Records</h3>

          <p>Lionel Messi and Cristiano Ronaldo have practically rewritten the Champions League record books as their careers have progressed, a selection of which are listed below. For the full range of Champions League records these guys have broken, head over to the <Link to={`/records/`} className="text-highlight underline">Records</Link> page.</p>

          <ul>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Goals:</strong> Cristiano Ronaldo ({rCLGoals} goals)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Assists:</strong> Cristiano Ronaldo ({rCLAssists} assists)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Goals in a Single Season:</strong> Cristiano Ronaldo (17 goals in 2013/14)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Hat-tricks:</strong> Lionel Messi and Cristiano Ronaldo ({rCLHatTricks} hat-tricks)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Goals in a Game:</strong> Lionel Messi (5 goals - shared with Luiz Adriano)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Free Kick Goals:</strong> Cristiano Ronaldo ({rCLFreeKicks} goals)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Consecutive Seasons Scored In:</strong> Lionel Messi (18 consecutive seasons)</li>
            <li><span role="img" aria-label="medal">🏅</span> <strong>Most Top Scorer Awards:</strong> Cristiano Ronaldo (7)</li>
          </ul>

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
          
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/ucl/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/ucl/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    ratingsImg: file(relativePath: { eq: "messi-ronaldo-champions-league-ratings.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ChampionsLeague
